<template>
  <a-card>
    <div class="top-header">
      <a-page-header title='素材开关管理' />
      <base-button
        :title="'返回'"
        @onClickBtn="handleBack"
        style="margin-left: 30px"
      ></base-button>
    </div>
    <a-form-model ref="form" :model="form" v-bind='layout'>
      <a-row type="flex" justify="start">
        <a-col :span="7">
          <a-form-model-item label="查询周期">
            <a-range-picker v-model="pickerTime" value-format="YYYY-MM-DD" :disabled-date="disabledDate"
                            :allow-clear="false" @calendarChange="calendarChange" @openChange="openChange" />
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="广告主">
            <a-input v-model.trim="form.advertiserName" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="素材ID">
            <a-input v-model.trim="form.adMaterialId" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="广告ID">
            <a-input v-model.trim="form.adPromotionId" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item label="素材名称">
            <a-input v-model.trim="form.title" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="start" style="margin-top: -16px">
        <a-col :span="4">
          <a-form-model-item label="系统开关状态">
            <a-select v-model="form.sysOptStatus" placeholder="请选择" allow-clear show-search
                      option-filter-prop="children">
              <a-select-option key="true" value="1">启用</a-select-option>
              <a-select-option key="false" value="2">暂停</a-select-option>
              <!-- <a-select-option key="false" value="3">删除</a-select-option> -->
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="素材状态">
            <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength="4" v-model="form.statusList"
                      placeholder="请选择" show-search allowClear>
              <a-select-option v-for="item in statusList" :key="item.code">{{ item.msg }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="素材类型">
            <a-select mode="multiple" :maxTagCount="1" :maxTagTextLength="4" v-model="form.adMaterialModeList"
                      placeholder="请选择" show-search allowClear>
              <a-select-option v-for="item in typeList" :key="item.code">{{ item.desc }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="隐藏0消费且0转化记录">
            <a-select placeholder="请选择" allowClear v-model="form.hidden">
              <a-select-option key="0">否</a-select-option>
              <a-select-option key="1">是</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="end" style="margin-top: -20px">
        <a-button @click="handleReset">重置</a-button>
        <a-button style="margin: 0 5px 0 20px;" type="primary" @click="handleSearch">查询</a-button>
      </a-row>
      <a-row type="flex" style="margin-top: -20px">
        <a-button :loading="btnLoading" style="margin-right: 20px;" @click="handlePatch(true)">批量启用</a-button>
        <a-button :loading="btnLoading" @click="handlePatch(false)">批量暂停</a-button>
      </a-row>
    </a-form-model>

    <a-table class="Atables" style="margin-top: 20px;" :row-selection="rowSelection" size="small"
             :loading="tableLoading"
             :columns="columns" :data-source="dataList" :rowKey="record => record.id" :pagination='false'
             :scroll="{ x: 2600 }" @change="handleTableChange">
      <div slot="advSlot">
        <div>素材</div>
        <!--<div>素材ID和广告ID</div>-->
      </div>
      <div slot="adPromotionId" slot-scope="text, record">
        <div class="blue-text" @click="handleOpenPage(record.id)"> {{ record.title }}</div>
        <!--<div class="gray-text">ID: {{ record.adMaterialId }} / {{ record.adPromotionId }}</div>-->
      </div>
      <div slot="reviewUrl" slot-scope="text, record" class="img-container">
        <img v-if="record.previewUrl" class="preview-url" :src="record.previewUrl" alt="预览图"
             @click="handleOpenPage(record.id)">
        <div v-else> 暂无图片</div>
      </div>
    </a-table>
    <base-pagination :currentPage="pagination.current" :pageSize="pagination.pageSize" :total="pagination.total"
                     @onChange="handlePaginationChange" @onShowSizeChange="handlePaginationChange" />

  </a-card>
</template>

<script>
import moment from 'moment';
import { isEmpty, pick } from 'lodash-es';

export default {
  data() {
    const columns = [
      {
        align: 'center',
        fixed: 'left',
        title: '皮皮慧投素材开关',
        width: 60,
        dataIndex: 'sysOptStatus',
        customRender(text) {
          const list = ['启用', '暂停'];
          return list[text - 1] || '';
        },
      },
      {
        align: 'center',
        fixed: 'left',
        title: '素材状态',
        width: 50,
        dataIndex: 'statusDesc',
        customRender(text) {
          return text || '-';
        },
      },
      {
        fixed: 'left',
        width: 200,
        align: 'center',
        key: 'adPromotionId',
        dataIndex: 'adPromotionId',
        slots: { title: 'advSlot' },
        scopedSlots: { customRender: 'adPromotionId' },
      },
      {
        fixed: 'left',
        title: '预览图',
        align: 'center',
        width: 100,
        scopedSlots: { customRender: 'reviewUrl' },
      },
      {
        align: 'center',
        fixed: 'left',
        title: '素材类型',
        width: 40,
        dataIndex: 'adMaterialModeDesc',
        customRender(text) {
          return text;
        },
      },
      {
        align: 'center',
        fixed: 'left',
        title: '广告主',
        width: 160,
        dataIndex: 'advertiserName',
        customRender(text) {
          return text || '';
        },
      }, {
        align: 'center',
        sorter: true,
        title: '消费(元)',
        key: 'statCost',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.statCost === 0 ? 0 : text.metric.statCost ? text.metric.statCost : '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '目标转化数',
        key: 'convertCnt',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.convertCnt === 0 ? 0 : text.metric.convertCnt ? text.metric.convertCnt : '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '转化单价(元)',
        key: 'convertCost',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.convertCost === 0 ? 0 : text.metric.convertCost ? text.metric.convertCost : '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '展示数',
        key: 'showCnt',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.showCnt === 0 ? 0 : text.metric.showCnt ? text.metric.showCnt : '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击数',
        key: 'clickCnt',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.clickCnt === 0 ? 0 : text.metric.clickCnt ? text.metric.clickCnt : '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信数',
        key: 'messageAction',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.messageAction === 0 ? 0 : text.metric.messageAction ? text.metric.messageAction : '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资数',
        key: 'clueMessageCount',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.clueMessageCount || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资单价（元）',
        key: 'unitPriceMessageAction',
        width: 80,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.unitPriceMessageAction || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '表单提交数',
        key: 'form',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.form || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '千次展示单价(元)',
        key: 'unitPricePerThousandShow',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.unitPricePerThousandShow || '-';
        },
      },

      {
        align: 'center',
        sorter: true,
        title: '展示转化率(%)',
        key: 'showConvertRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.showConvertRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击率(%)',
        key: 'clickRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.clickRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击转化率(%)',
        key: 'clickConvertRate',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric.clickConvertRate || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '点击单价（元）',
        key: 'clickUnitPrice',
        width: 60,
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.clickUnitPrice || '-';
        },
      },
      {
        align: 'center',
        sorter: true,
        title: '私信留资率(%)',
        key: 'clueMessageCountRate',
        sortDirections: ['descend', 'ascend'],
        customRender(text) {
          return text.metric?.clueMessageCountRate || '-';
        },
      },
    ];
    return {
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 15 },
      },
      form: {
        advertiserName: undefined,
        adPorjectName: undefined,
        statusList: [],
        hidden: '0',
      },
      pickerTime: [moment(), moment()],
      selectStartDay: '',
      tableLoading: false,
      columns,
      pagination: {
        current: 1,
        pageSize: 50,
        total: 0,
      },
      dataList: [],
      selectedRowKeys: [],
      typeList: [],
      statusList: [],
      btnLoading: false,
      sortList: [],
    };
  },
  mounted() {
    if (this.$route.query.DATA_TIME) {
      this.form.adMaterialId = this.$route.query.id;
      this.pickerTime = this.$route.query.DATA_TIME;
    } else if (this.$route.params.id) {
      this.form.adPromotionId = this.$route.params.id;
    } else {
      try {
        const params = JSON.parse(sessionStorage.getItem('__material_switch_manage__') || '{}');
        if (!isEmpty(params)) {
          this.form = {
            ...this.form, ...pick(params, ['adMaterialId',
              'adMaterialModeList',
              'adPromotionId',
              'advertiserName',
              'statusList',
              'sysOptStatus',
              'title',
              'hidden',
            ]),
          };
          if (params.startTime) this.pickerTime = [moment(params.startTime), moment(params.endTime)];
          // this.sortList = params.sortList;
          this.pagination.current = params.page;
          this.pagination.pageSize = params.size;
        }
      } finally {
      }
    }


    this.$nextTick(this.init());
  },
  methods: {
    async init() {
      await this.getTypeList();
      await this.getStatusList();
      await this.getDataList();
    },
    async getStatusList() {
      await this.handleRequest('getMaterialStatusList', (data) => {
        this.statusList = data;
        // 默认选中
        const params = JSON.parse(sessionStorage.getItem('__material_switch_manage__') || '{}');
        if (!isEmpty(params?.statusList && !this.$route.params.id)) {
          this.form.statusList = params.statusList;
        } else {
          // 默认选中
          const unchooseList = ['MATERIAL_STATUS_MATERIAL_DELETE', 'MATERIAL_STATUS_DELETE'];
          this.form.statusList = this.statusList.filter(val => unchooseList.indexOf(val.code) === -1).map((value) => (value.code));
        }
      });
    },
    async getTypeList() {
      await this.handleRequest('getMaterialTypeList', (data) => {
        this.typeList = data;
      });
    },
    async getDataList() {
      const startTime = `${ moment(this.pickerTime[0]).format('YYYY-MM-DD') } 00:00:00`;
      const endTime = `${ moment(this.pickerTime[1]).format('YYYY-MM-DD') } 23:59:59`;
      const {
        adMaterialId,
        adMaterialModeList,
        adPromotionId,
        advertiserName,
        statusList,
        sysOptStatus,
        title,
        hidden,
      } = this.form;
      const params = {
        startTime,
        endTime,
        adMaterialId,
        adMaterialModeList,
        adPromotionId,
        advertiserName,
        statusList,
        sysOptStatus,
        title,
        hidden,
        sortList: this.sortList,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      sessionStorage.setItem('__material_switch_manage__', JSON.stringify(params));
      this.tableLoading = true;
      this.handleRequest('getMaterialList', (data) => {
        this.dataList = data.list;
        this.pagination.total = data.total || 0;
      }, params).finally(() => this.tableLoading = false);
    },
    async handleRequest(API, callback, params = null) {
      const { code, message, data } = await this.$api.core.JLAdvertManage[API](params);
      if (code === 200) {
        callback(data);
      } else {
        this.$message.error(`${ message }`);
      }
    },
    disabledDate(current) {
      return current > this.$moment(this.selectStartDay).add(31, 'days') || current < this.$moment(this.selectStartDay).subtract(31, 'days');
    },
    calendarChange(dates) {
      this.selectStartDay = dates[0];
    },
    openChange() {
      this.selectStartDay = '';
    },
    handleTableChange(pagination, filters, sorter) {
      const sort = sorter.order ? [{
        fieldName: sorter.columnKey,
        orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
      }] : [];
      this.sortList = !!sorter.order ? sort : [{ fieldName: 'statCost', orderType: 'desc' }];
      this.pagination.current = 1;
      this.getDataList();
    },
    handleOpenPage(id) {
      this.handleRequest('getMaterialUrl', (data) => {
        window.open(data, '_blank');
      }, id);
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    handleReset() {
      this.form = this.$options.data().form;
      this.pickerTime = [moment(), moment()];
      this.selectStartDay = '';
      const unchooseList = ['MATERIAL_STATUS_MATERIAL_DELETE', 'MATERIAL_STATUS_DELETE'];
      this.form.statusList = this.statusList.filter(val => unchooseList.indexOf(val.code) === -1).map((value) => (value.code));
    },
    handlePatch(isOpen) {
      if (!this.selectedRowKeys.length) return this.$message.info('请至少选中一条素材');
      this.btnLoading = true;
      const API_NAME = isOpen ? 'materialPatchEnable' : 'materialPatchDisable';
      this.handleRequest(API_NAME, () => {
        this.$message.success('操作成功，30分钟内完成');
        this.selectedRowKeys = [];
        this.getDataList();
      }, this.selectedRowKeys).finally(() => this.btnLoading = false);
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    handleBack() {
      this.$router.back();
    },
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
          this.selectedRowKeys = selectedRowKeys;
        },
        getCheckboxProps: record => (
          {
            props: {},
          }
        ),
      };
    },
  },
};
</script>

<style scoped lang="less">
.img-container {
  width: 100%;
  text-align: center;
  height: 75px;
  line-height: 75px;

  img {
    height: 100%;
    object-fit: cover;
  }
}

.gray-text {
  color: #bbbbbb
}

.blue-text {
  color: #599af8;
  cursor: pointer;
  text-decoration: underline;
}

.preview-url {
  cursor: pointer;
}

.top-header {
  display: flex;
  align-items: center;
  margin-top: -30px;
}

.Atables /deep/ .ant-table {
  font-size: 10px;
}

.Atables /deep/ .ant-table th,
.Atables /deep/ .ant-table td {
  font-size: 10px;
}
</style>
